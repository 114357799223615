import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import icon from "/favicon.svg";

function Footer() {
  return (
    <Stack
      flexDirection={"row"}
      alignItems={"center"}
      py={1}
      px={4}
      gap={3}
      sx={{ boxShadow: (theme) => theme.shadows[9] }}
    >
      <img src={icon} height={40} />
      <Stack flexDirection={"row"} gap={4} flexGrow={1}>
        <Typography color="text.secondary" noWrap>
          Term of use
        </Typography>
        <Typography color="text.secondary" noWrap>
          Privacy policy
        </Typography>
        <Typography color="text.secondary" noWrap>
          Cookies
        </Typography>
        <Typography color="text.secondary" noWrap>
          About AssuredPay
        </Typography>
      </Stack>
      <Typography variant="body2" color="text.secondary" noWrap>
        © 2025 AssuredPay | All rights reserved
      </Typography>
    </Stack>
  );
}

export default Footer;
