import { useState, useEffect, RefObject } from "react";

// ----------------------------------------------------------------------

type ReturnType = boolean;

interface UseScrollOptions {
  container?: RefObject<HTMLElement>;
  target?: RefObject<HTMLElement>;
}

export default function useOffSetTop(top = 100, options?: UseScrollOptions): ReturnType {
  const [value, setValue] = useState(false);

  useEffect(() => {
    const container = options?.container?.current || window;

    const handleScroll = () => {
      const scrollY = options?.container?.current
        ? options.container.current.scrollTop
        : window.scrollY;

      setValue(scrollY > top);
    };

    container.addEventListener("scroll", handleScroll);
    handleScroll(); // Set initial value

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [top, options?.container]);

  return value;
}

// Usage Example
// const offset = useOffSetTop(100);

// Or with a container
// const offset = useOffSetTop(100, { container: ref });
