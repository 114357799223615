import { createRoot } from "react-dom/client";
import { AuthProvider } from "./auth/JwtContext";
import { SettingsProvider } from "./components/settings";
import ScrollToTop from "./components/scroll-to-top";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

createRoot(document.getElementById("root")!).render(
  <AuthProvider>
    <SettingsProvider>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </SettingsProvider>
  </AuthProvider>,
);
