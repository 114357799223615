import {
  CheckCircleOutline,
  ErrorOutlineOutlined,
  PriorityHighOutlined,
  WarningOutlined,
  HourglassEmptyOutlined, // Loading icon
} from "@mui/icons-material";
import { toast, Id } from "react-toastify";

let activeLoadingToastId: Id | null = null; // Track the active loading toast

const activeToasts = new Set<string>(); // Track active toast IDs

const toastStyle: React.CSSProperties = {
  minHeight: 10,
  padding: 10,
  marginBottom: 5,
  paddingRight: 30,
  width: "fit-content",
  textAlign: "center",
};

export const showToast = {
  success: (message: string) => showUniqueToast("success", message),
  error: (message: string) => showUniqueToast("error", message),
  info: (message: string) => showUniqueToast("info", message),
  warning: (message: string) => showUniqueToast("warning", message),
  loading: (message: string) => showLoadingToast(message), // Show a single loading toast
  dismissLoading: () => dismissLoadingToast(), // Dismiss the loading toast
};

const showUniqueToast = (
  type: "success" | "error" | "info" | "warning",
  message: string
) => {
  const toastId = `${type}-${message}`;

  if (!activeToasts.has(toastId)) {
    activeToasts.add(toastId);

    const toastOptions = {
      icon: getIcon(type),
      closeButton: false,
      style: getToastStyle(type),
      onClose: () => activeToasts.delete(toastId),
    };

    switch (type) {
      case "success":
        toast.success(message, toastOptions);
        break;
      case "error":
        toast.error(message, toastOptions);
        break;
      case "info":
        toast.info(message, toastOptions);
        break;
      case "warning":
        toast.warning(message, toastOptions);
        break;
    }
  }
};

// Show a single loading toast
const showLoadingToast = (message: string) => {
  if (!activeLoadingToastId) {
    activeLoadingToastId = toast.loading(message, {
      icon: HourglassEmptyOutlined,
      closeButton: false,
      style: getToastStyle("loading"),
    });
  }
};

// Dismiss the loading toast
const dismissLoadingToast = () => {
  if (activeLoadingToastId) {
    toast.dismiss(activeLoadingToastId);
    activeLoadingToastId = null;
  }
};

const getIcon = (type: string) => {
  switch (type) {
    case "success":
      return CheckCircleOutline;
    case "error":
      return ErrorOutlineOutlined;
    case "info":
      return PriorityHighOutlined;
    case "warning":
      return WarningOutlined;
    case "loading":
      return HourglassEmptyOutlined; // Loading icon
    default:
      return undefined;
  }
};

const getToastStyle = (type: string) => {
  switch (type) {
    case "success":
      return { color: "#1e4620", backgroundColor: "#edf7ed", ...toastStyle };
    case "error":
      return { color: "#5f2120", backgroundColor: "#fdeded", ...toastStyle };
    case "info":
      return { color: "#014361", backgroundColor: "#e5f6fd", ...toastStyle };
    case "warning":
      return { color: "#663c00", backgroundColor: "#fff4e5", ...toastStyle };
    case "loading":
      return { color: "#3f3f3f", backgroundColor: "#f0f0f0", ...toastStyle };
    default:
      return toastStyle;
  }
};
