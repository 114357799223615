import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

// Define animations inside a global style component
const GlobalStyles = styled("style")`
  @keyframes rotateClockwise {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes rotateAntiClockwise {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`;

const Loader = ({
  width,
  isSuccess = false,
}: {
  width: number;
  isSuccess?: boolean;
}) => {
  const LoaderContainer = styled(Box)({
    position: "relative",
    height: width,
    width: width,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  const OuterRing = styled(Box)(({ theme }) => ({
    position: "absolute",
    width: "65%",
    height: "65%",
    border: `${width / 15}px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
    borderTopColor: "transparent",
    animation: "rotateClockwise 2s linear infinite",
  }));

  const InnerRing = styled(Box)(({ theme }) => ({
    position: "absolute",
    width: "40%",
    height: "40%",
    border: `${width / 15}px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
    borderTopColor: "transparent",
    animation: "rotateAntiClockwise 2s linear infinite",
  }));

  const CheckMark = styled(Box)({
    position: "absolute",
    fontSize: width / 5,
    fontWeight: "bold",
    color: "navy",
  });

  return (
    <>
      <GlobalStyles />
      <LoaderContainer>
        <OuterRing />
        <InnerRing />
        {!isSuccess && <CheckMark>✔</CheckMark>}
      </LoaderContainer>
    </>
  );
};

export default Loader;
