import { Bounce, Slide, ToastContainer } from "react-toastify";
import { ThemeSettings } from "./components/settings";
import SnackbarProvider from "./components/snackbar";
import Router from "./routes";
import ThemeProvider from "./theme";

function App() {
  return (
    <ThemeProvider>
      <ThemeSettings>
        <SnackbarProvider>
          <Router />
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            theme="light"
            transition={Slide}
          />
        </SnackbarProvider>
      </ThemeSettings>
    </ThemeProvider>
  );
}

export default App;
