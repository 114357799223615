// @mui
import { Typography, Stack, Box, useTheme } from "@mui/material";
// components
import Logo from "../../components/logo";
import Image from "../../components/image";
//
import {
  StyledRoot,
  StyledSectionBg,
  StyledSection,
  StyledContent,
} from "./styles";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

import { motion } from "framer-motion";
import { NAV } from "../../config";

// ----------------------------------------------------------------------

export default function GuestLayout() {
  const theme = useTheme();

  return (
    <StyledRoot>
      <Box
        component={motion.div}
        sx={{
          bgcolor: "background.default",
          width: "100%",
          position: "fixed",
          zIndex: 9,
        }}
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <Header />
      </Box>
      <StyledContent>
        <Stack sx={{ width: 1 }}>
          <Outlet />
        </Stack>
      </StyledContent>

      <Box
        component={motion.div}
        sx={{
          display: "none",
          position: "relative",
          padding: `${NAV.H_DASHBOARD_ITEM * 2}px ${NAV.H_DASHBOARD_ITEM * 1}px`,
          [theme.breakpoints.up("md")]: {
            backgroundColor: theme.palette.primary.main,
            display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            flexDirection: "column",
          },
        }}
        initial={{ flexGrow: 0 }}
        animate={{ flexGrow: 1 }}
      >
        <Typography variant="h1" color="common.white">
          Welcome to AssuredPay
        </Typography>
        <Typography variant="h2" color="common.white">
          Securely manage your payments & receivables.
        </Typography>
        <motion.div
          variants={{
            hidden: { opacity: 0 },
            visible: (i = 1) => ({
              opacity: 1,
              transition: { staggerChildren: 0.12, delayChildren: 0.04 * i },
            }),
          }}
          initial="hidden"
          animate="visible"
        >
          {"Effortlessly manage payments, receivables, and"
            .split(" ")
            .map((word, index) => (
              <Typography
                key={index}
                component={motion.span}
                variant="h3"
                color="common.white"
                mt={3}
                sx={{ display: "inline-block", marginRight: 1 }}
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: {
                    opacity: 1,
                    y: 0,
                    transition: { type: "spring", stiffness: 100 },
                  },
                }}
              >
                {word}
              </Typography>
            ))}
        </motion.div>
        <motion.div
          variants={{
            hidden: { opacity: 0 },
            visible: (i = 1) => ({
              opacity: 1,
              transition: {
                staggerChildren: 0.12,
                delayChildren: 0.8 + 0.04 * i,
              },
            }),
          }}
          initial="hidden"
          animate="visible"
        >
          {"credit with secure banking integration."
            .split(" ")
            .map((word, index) => (
              <Typography
                key={index}
                component={motion.span}
                variant="h3"
                color="common.white"
                sx={{ display: "inline-block", marginRight: 1 }}
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: {
                    opacity: 1,
                    y: 0,
                    transition: { type: "spring", stiffness: 100 },
                  },
                }}
              >
                {word}
              </Typography>
            ))}
        </motion.div>

        <StyledSectionBg />
      </Box>

      <Box
        component={motion.div}
        sx={{
          bgcolor: "background.default",
          width: "100%",
          position: "fixed",
          bottom: 0,
          zIndex: 9,
        }}
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <Footer />
      </Box>
    </StyledRoot>
  );
}
