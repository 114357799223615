import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import typography from "../../../theme/typography";
import { motion } from "framer-motion";
import { useAuthContext } from "../../../auth/useAuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { TabsData } from "../../../utils/common";

function KycStepper() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { pathname } = useLocation();

  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setActiveTab([3, 4].includes(user.profileStatus) ? 3 : user.profileStatus);
  }, [pathname]);

  return (
    <Box>
      <Tabs
        value={activeTab}
        aria-label="basic tabs example"
        indicatorColor="secondary"
      >
        {TabsData.map((item) => (
          <Tab
            key={item.value}
            label={
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: item.value * 0.1 }}
              >
                {item.label}
              </motion.div>
            }
            value={item.value}
            sx={{
              fontSize: typography.h4,
              margin: "0px !important",
              px: 2,
              "&:not(.Mui-selected)": {
                color:
                  item.value < activeTab ? "primary.main" : "text.disabled", // Change unselected tab color
                cursor: "not-allowed",
              },
              "&.Mui-selected": {
                color: "secondary.main",
                fontWeight: "bold",
                cursor: "default",
              },
              "&::after":
                item.value < activeTab
                  ? {
                      content: '""',
                      display: "block",
                      width: "100%",
                      height: "2px",
                      backgroundColor: "primary.main",
                      position: "absolute",
                      bottom: "0",
                    }
                  : {},
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default React.memo(KycStepper);
