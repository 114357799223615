import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import Iconify from "../../components/iconify";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH_AUTH } from "../../routes/paths";
import Logo from "../../components/logo";
import { useAuthContext } from "../../auth/useAuthContext";

function Header() {
  const { logout } = useAuthContext();

  return (
    <Stack
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      py={2}
      px={4}
    >
      <Logo />
      <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
        <Button
          variant="outlined"
          color="secondary"
          endIcon={<Iconify icon="mingcute:exit-line" width="24" height="24" />}
          onClick={logout}
        >
          Logout
        </Button>

        {/* <Iconify icon={"mdi:search"} height={40} width={40} /> */}
      </Stack>
    </Stack>
  );
}

export default Header;
