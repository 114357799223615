import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Iconify from "../../components/iconify";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH_AUTH } from "../../routes/paths";
import Logo from "../../components/logo";

function Header() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const dynamicHeader = ["/login", "/reset-password", "/login-verify"].includes(
    pathname
  ) ? (
    <>
      {" "}
      <Typography>Don’t have and account?</Typography>
      <Typography
        color="secondary.main"
        sx={{ cursor: "pointer" }}
        onClick={() => navigate(PATH_AUTH.signUp)}
      >
        Sign up
      </Typography>
    </>
  ) : (
    <>
      {" "}
      <Typography>Already have account?</Typography>
      <Typography
        color="secondary.main"
        sx={{ cursor: "pointer" }}
        onClick={() => navigate(PATH_AUTH.login)}
      >
        Sign in
      </Typography>
    </>
  );

  return (
    <Stack
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      py={2}
      px={4}
    >
      <Logo />
      <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
        {dynamicHeader}
        {/* <Iconify icon={"mdi:search"} height={40} width={40} /> */}
      </Stack>
    </Stack>
  );
}

export default Header;
