// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/auth";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: "/login",
  loginVerify: "/login-verify",
  resetPassword: "/reset-password",
  signUp: "/sign-up",
  signUpMobileVerify: "/sign-up-mobile-verify",
  signUpEmailVerify: "/sign-up-email-verify",
};

export const PATH_KYC = {
  businessVerification: "/kyc/businessVerification",
  businessDocuments: "/kyc/businessDocuments",
  directorAndPromoter: "/kyc/directorAndPromoter",
  DirectorDocuments: "/kyc/DirectorDocuments",
  ApplicationReview: "/kyc/ApplicationReview",
};

export const PATH_BANK = {
  BankSelection: "/bank/BankSelection",
  BankSelectionSteps: "/bank/BankSelectionSteps",
  BankOSV: "/bank/BankOSV",
  BankAccountOpen: "/bank/BankAccountOpen",
  BankAccountSetUp: "/bank/BankAccountSetUp",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashboard: path(ROOTS_DASHBOARD, "/dashboard"),
  one: path(ROOTS_DASHBOARD, "/one"),
  two: path(ROOTS_DASHBOARD, "/two"),
  three: path(ROOTS_DASHBOARD, "/three"),
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    four: path(ROOTS_DASHBOARD, "/user/four"),
    five: path(ROOTS_DASHBOARD, "/user/five"),
    six: path(ROOTS_DASHBOARD, "/user/six"),
  },
};
