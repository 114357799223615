import { ReactNode } from "react";
import { Navigate, useNavigate } from "react-router-dom";
// components
import LoadingScreen from "../components/loading-screen";
//
import { useAuthContext } from "./useAuthContext";
import { PATH_AUTH, PATH_DASHBOARD, PATH_KYC } from "../routes/paths";
import { TabsData } from "../utils/common";

// ----------------------------------------------------------------------

type BankGuardProps = {
  children: ReactNode;
};

export default function BankGuard({ children }: BankGuardProps) {
  const { user, isAuthenticated, isInitialized } = useAuthContext();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Navigate to={PATH_AUTH.login} />;
  }

  return <>{children}</>;
}
