export const END_POINTS = {
  AUTH: {
    USER: "user",
    LOGIN: "auth/login",
    VERIFY: "auth/verify",
    SIGN_UP: "auth/register",
  },
  KYC: {
    GST: {
      FETCH: (gstNumber: string) => `business/fetch-gst-details/${gstNumber}`,
      SAVE: `business`,
      FETCH_BUSINESS_DETAIL: `business`,
      FETCH_DOCUMENTS: `business-documents`,
      UPLOAD_DOC: (fileType: string) => `business/upload/${fileType}`,
      MARK_DOC_COMPLETE: "business/business-documents/completed",
    },
    AADHAAR: {
      OTP: "business/verify-uidai",
      VERIFY: "business/verify-uidai-otp",
      PAN_VERIFY: (panNumber: string) => `business/verify-pan/${panNumber}`,
      MARK_KYC_DOC_COMPLETE: `business/kyc-documents/completed`,
    },
    BANK: {
      FETCH: "banks",
      FETCH_BRANCH: (bankId: string) => `bank-branches/${bankId}`,
      SAVE_BANK: `business/bank-details`,
      SAVE_OSV: `business/bank-osv`,
    },
  },
};
