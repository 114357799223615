import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import icon from "/favicon.svg";

function Footer() {
  return (
    <Stack
      flexDirection={"row"}
      alignItems={"center"}
      py={1}
      px={4}
      gap={4}
      sx={{ boxShadow: (theme) => theme.shadows[9] }}
    >
      <img src={icon} height={40} />
      <Stack flexDirection={"row"} gap={4} flexGrow={1}>
        <Typography color="text.secondary">Term of use</Typography>
        <Typography color="text.secondary">Privacy policy</Typography>
        <Typography color="text.secondary">Cookies</Typography>
        <Typography color="text.secondary">About AssuredPay</Typography>
      </Stack>
      <Typography variant="body2" color="text.secondary">
        © 2025 AssuredPay | All rights reserved
      </Typography>
    </Stack>
  );
}

export default Footer;
