import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, Link, BoxProps, Stack, Typography } from "@mui/material";

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 30,
          height: 30,
          display: "inline-flex",
          ...sx,
        }}
        {...other}
      >
        <Stack flexDirection={"row"} alignItems={"center"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="44.58"
            viewBox="0 0 39.961 44.58"
          >
            <g id="favicon-icon" transform="translate(-1852.53 -4828)">
              <path
                id="Path_5805"
                data-name="Path 5805"
                d="M38.96,57.6A22.29,22.29,0,1,1,54.48,19.31a2,2,0,0,1-2.79,2.87,18.294,18.294,0,1,0,1.4,24.73,2,2,0,1,1,3.09,2.54A22.208,22.208,0,0,1,38.95,57.6Z"
                transform="translate(1835.86 4814.98)"
                fill="#303981"
              />
              <path
                id="Path_5806"
                data-name="Path 5806"
                d="M38.86,50.15a14.813,14.813,0,0,1-10.34-4.18,2,2,0,0,1,2.79-2.87A10.848,10.848,0,0,0,46.42,27.53a10.611,10.611,0,0,0-7.72-3.06,10.752,10.752,0,0,0-7.62,3.29,2,2,0,1,1-2.87-2.79A14.844,14.844,0,1,1,38.86,50.14Z"
                transform="translate(1835.86 4814.98)"
                fill="#fa8334"
              />
              <path
                id="Path_5817"
                data-name="Path 5817"
                d="M39.49,41.31a1.994,1.994,0,0,1-1.91-.66l-5.21-6.06a2,2,0,1,1,3.03-2.61l3.91,4.54L51.79,25.79a2,2,0,1,1,2.61,3.03l-14,12.03a1.98,1.98,0,0,1-.91.44Z"
                transform="translate(1835.86 4814.98)"
                fill="#303981"
              />
            </g>
          </svg>
          <Typography fontSize={24} color="primary.dark" pb={1}>
            AssuredPay
          </Typography>
        </Stack>
      </Box>
    );

    if (disabledLink) {
      return <>{logo}</>;
    }

    return (
      <Link to="/" component={RouterLink} sx={{ display: "contents" }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
