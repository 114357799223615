import { PATH_BANK, PATH_DASHBOARD, PATH_KYC } from "../routes/paths";

export function hideEmail(email: string) {
  const [local, domain] = email.split("@");
  const hiddenLocal =
    local[0] + "*".repeat(local.length - 2) + local[local.length - 1];
  return hiddenLocal + "@" + domain;
}

export function hideMobileNumber(number: string) {
  return number.slice(0, 2) + "*".repeat(number.length - 4) + number.slice(-2);
}

export function customSnakeCase(str: string) {
  return str.toLowerCase().replaceAll(" ", "_");
}

export function getLocation() {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log("Latitude:", position.coords.latitude);
        console.log("Longitude:", position.coords.longitude);
      },
      (error) => {
        console.error("Error getting location:", error.message);
      }
    );
  } else {
    console.error("Geolocation is not supported by this browser.");
  }
}

export const NavigationSteps = [
  { status: [0], url: PATH_KYC.businessVerification },
  { status: [2], url: PATH_KYC.businessDocuments },
  { status: [3, 4], url: PATH_KYC.directorAndPromoter },
  { status: [5], url: PATH_KYC.DirectorDocuments },
  { status: [6, 7], url: PATH_KYC.ApplicationReview },
  { status: [8], url: PATH_BANK.BankSelection },
  { status: [9], url: PATH_DASHBOARD.dashboard },
];
export const TabsData = [
  {
    label: "Business Verification",
    url: "/kyc/businessVerification",
    value: 0,
  },
  { label: "Business Documents", url: "/kyc/businessDocuments", value: 2 },
  {
    label: "Director / Promoter(s)",
    url: "/kyc/directorAndPromoter",
    value: 3,
  },
  { label: "Director Document", url: "/kyc/DirectorDocuments", value: 5 },
  { label: "Application review", url: "/kyc/applicationReview", value: 6 },
];
