// @mui
import { Box, Container } from "@mui/material";
//
import { StyledRoot, StyledContent } from "./styles";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import KycStepper from "../../pages/auth/kyc/KycStepper";
import { createContext, useEffect, useState } from "react";
import fetcher from "../../api/fetcher";
import { END_POINTS } from "../../api/EndPoints";
import { businessDetails } from "../../api/types/ressponse/kyc";
import { PATH_KYC } from "../../routes/paths";
import { useAuthContext } from "../../auth/useAuthContext";
import { NavigationSteps, TabsData } from "../../utils/common";
import { BanksData } from "../../api/types/ressponse/bank";

// ----------------------------------------------------------------------
export const BusinessDetailsContext = createContext(
  {} as businessDetails & { getBusinessDetail: () => void }
);
export const BankContext = createContext(
  {} as {
    bankData: BanksData[];
    selectedBank: string;
    setSelectedBank: (bankId: string) => void;
  }
);

export default function KYCLayout() {
  const { pathname } = useLocation();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [businessDetail, setBusinessDetail] = useState({} as businessDetails);
  const [bankData, setBankData] = useState<BanksData[]>([]);
  const [selectedBank, setSelectedBank] = useState("");

  useEffect(() => {
    getBusinessDetail();
  }, [pathname]);

  useEffect(() => {
    const requestedPathname =
      NavigationSteps.find((item) => item.status.includes(user.profileStatus))
        ?.url || "";
    if (requestedPathname !== pathname) {
      navigate(requestedPathname);
    }
  }, [user]);

  const getBusinessDetail = async () => {
    try {
      const Response = await fetcher.get(
        END_POINTS.KYC.GST.FETCH_BUSINESS_DETAIL
      );
      setBusinessDetail(Response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    pathname.split("/").includes("bank") && getBankList();
  }, []);

  const getBankList = async () => {
    try {
      const Response = await fetcher.get(END_POINTS.KYC.BANK.FETCH);
      setBankData(Response.data);
      setSelectedBank(Response.data[0]._id);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <StyledRoot>
      <Box
        sx={{
          bgcolor: "background.default",
          width: "100%",
          position: "fixed",
          zIndex: 9,
        }}
      >
        <Header />
      </Box>
      <StyledContent>
        <Container maxWidth={"xl"}>
          <BusinessDetailsContext.Provider
            value={{ ...businessDetail, getBusinessDetail }}
          >
            {user.profileStatus < 8 && <KycStepper />}
            <BankContext.Provider
              value={{ bankData, selectedBank, setSelectedBank }}
            >
              <Outlet />
            </BankContext.Provider>
          </BusinessDetailsContext.Provider>
        </Container>
      </StyledContent>

      <Box
        sx={{
          bgcolor: "background.default",
          width: "100%",
          position: "fixed",
          bottom: 0,
          zIndex: 9,
        }}
      >
        <Footer />
      </Box>
    </StyledRoot>
  );
}
