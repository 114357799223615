import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config";
//
import {
  Page404,
  PageOne,
  PageTwo,
  PageSix,
  PageFour,
  PageFive,
  LoginPage,
  PageThree,
  LoginVerifyPage,
  ResetPassword,
  SignUpPage,
  SignUpEmailVerify,
  SignUpMobileVerify,
  BusinessVerification,
  BusinessDocuments,
  DirectorAndPromoter,
  DirectorDocuments,
  ApplicationReview,
  BankSelection,
  BankSelectionSteps,
  BankOSV,
  BankAccountOpen,
  BankAccountSetUp,
  Dashboard,
} from "./elements";
import GuestLayout from "../layouts/Guest";
import KYCLayout from "../layouts/Kyc";
import KycGuard from "../auth/KycGuard";
import BankGuard from "../auth/BankGuard";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <GuestGuard>
          <GuestLayout />
        </GuestGuard>
      ),
      children: [
        { element: <Navigate to={"/login"} replace />, index: true },
        { path: "login", element: <LoginPage /> },
        { path: "login-verify", element: <LoginVerifyPage /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "sign-up", element: <SignUpPage /> },
        { path: "sign-up-mobile-verify", element: <SignUpMobileVerify /> },
        { path: "sign-up-email-verify", element: <SignUpEmailVerify /> },
      ],
    },
    {
      path: "/kyc",
      element: (
        <KycGuard>
          <KYCLayout />
        </KycGuard>
      ),
      children: [
        {
          element: <Navigate to={"/kyc/businessVerification"} replace />,
          index: true,
        },
        { path: "businessVerification", element: <BusinessVerification /> },
        { path: "businessDocuments", element: <BusinessDocuments /> },
        { path: "directorAndPromoter", element: <DirectorAndPromoter /> },
        { path: "DirectorDocuments", element: <DirectorDocuments /> },
        { path: "ApplicationReview", element: <ApplicationReview /> },
      ],
    },
    {
      path: "/bank",
      element: (
        <BankGuard>
          <KYCLayout />
        </BankGuard>
      ),
      children: [
        {
          element: <Navigate to={"/bank/BankSelection"} replace />,
          index: true,
        },
        { path: "BankSelection", element: <BankSelection /> },
        { path: "BankSelectionSteps", element: <BankSelectionSteps /> },
        { path: "BankOSV", element: <BankOSV /> },
        { path: "BankAccountOpen", element: <BankAccountOpen /> },
        { path: "BankAccountSetUp", element: <BankAccountSetUp /> },
      ],
    },
    {
      path: "/auth",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "dashboard", element: <Dashboard /> },
        { path: "one", element: <PageOne /> },
        { path: "two", element: <PageTwo /> },
        { path: "three", element: <PageThree /> },
        {
          path: "user",
          children: [
            {
              element: <Navigate to="/dashboard/user/four" replace />,
              index: true,
            },
            { path: "four", element: <PageFour /> },
            { path: "five", element: <PageFive /> },
            { path: "six", element: <PageSix /> },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
