import { Suspense, lazy, ElementType } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// ----------------------------------------------------------------------

export const LoginPage = Loadable(
  lazy(() => import("../pages/auth/login/LoginPage"))
);
export const LoginVerifyPage = Loadable(
  lazy(() => import("../pages/auth/login/LoginVerifyPage"))
);
export const ResetPassword = Loadable(
  lazy(() => import("../pages/auth/login/ResetPassword"))
);

//registration
export const SignUpPage = Loadable(
  lazy(() => import("../pages/auth/registration/SignUpPage"))
);
export const SignUpMobileVerify = Loadable(
  lazy(() => import("../pages/auth/registration/SignUpMobileVerify"))
);
export const SignUpEmailVerify = Loadable(
  lazy(() => import("../pages/auth/registration/SignUpEmailVerify"))
);

// kyc
export const BusinessVerification = Loadable(
  lazy(() => import("../pages/auth/kyc/BusinessVerification"))
);
export const BusinessDocuments = Loadable(
  lazy(() => import("../pages/auth/kyc/BusinessDocuments"))
);
export const DirectorAndPromoter = Loadable(
  lazy(() => import("../pages/auth/kyc/DirectorAndPromoter"))
);
export const DirectorDocuments = Loadable(
  lazy(() => import("../pages/auth/kyc/DirectorDocuments"))
);
export const ApplicationReview = Loadable(
  lazy(() => import("../pages/auth/kyc/ApplicationReview"))
);

//banks
export const BankSelection = Loadable(
  lazy(() => import("../pages/auth/banks/BankSelection"))
);
export const BankSelectionSteps = Loadable(
  lazy(() => import("../pages/auth/banks/BankSelectionSteps"))
);
export const BankOSV = Loadable(
  lazy(() => import("../pages/auth/banks/BankOSV"))
);
export const BankAccountOpen = Loadable(
  lazy(() => import("../pages/auth/banks/BankAccountOpen"))
);
export const BankAccountSetUp = Loadable(
  lazy(() => import("../pages/auth/banks/BankAccountSetUp"))
);

//dashboard
export const Dashboard = Loadable(lazy(() => import("../pages/Dashboard")));

export const PageOne = Loadable(lazy(() => import("../pages/PageOne")));
export const PageTwo = Loadable(lazy(() => import("../pages/PageTwo")));
export const PageThree = Loadable(lazy(() => import("../pages/PageThree")));
export const PageFour = Loadable(lazy(() => import("../pages/PageFour")));
export const PageFive = Loadable(lazy(() => import("../pages/PageFive")));
export const PageSix = Loadable(lazy(() => import("../pages/PageSix")));

export const Page404 = Loadable(lazy(() => import("../pages/Page404")));
