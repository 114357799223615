import { ReactNode } from "react";
import { Navigate, useNavigate } from "react-router-dom";
// components
import LoadingScreen from "../components/loading-screen";
//
import { useAuthContext } from "./useAuthContext";
import { PATH_BANK, PATH_DASHBOARD, PATH_KYC } from "../routes/paths";
import { NavigationSteps, TabsData } from "../utils/common";

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { user, isAuthenticated, isInitialized } = useAuthContext();

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    return (
      <Navigate
        to={
          NavigationSteps.find((item) =>
            item.status.includes(user.profileStatus)
          )?.url || ""
        }
      />
    );
  }

  return <>{children}</>;
}
